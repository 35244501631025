import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { Routes } from '@/constants';
import { useBookmarkManagement } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  getIsEpisodeEthics,
  playerThunks,
  selectIsEpisodeRedeemRequired,
  selectIsFreeTrialUser,
  selectUserRegion,
  useFetchCategoriesQuery,
  userEpisodesThunks,
  useShowProductFeedbackIfReadyMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { APP_LAYOUT_PX } from '../AppLayout';
import { EpisodeBanner as EpisodeBannerComponent } from './EpisodeBanner.component';
import { filterEpisodeBannerCategories } from './utils';

export const EpisodeBanner = ({ episode, referrer, actions }) => {
  const dispatch = useDispatch();

  const userRegion = useSelector(selectUserRegion);
  const isFreeTrialUser = useSelector(selectIsFreeTrialUser);
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episode.episodeId),
  );
  const isEpisodeDetails = useMatch(Routes.EPISODE);

  const { ref } = useInView({
    threshold: 1,
    triggerOnce: true,
    onChange: inView => {
      if (inView && !isEpisodeDetails) {
        AnalyticsService.episodeImpression({
          episode,
          location: { ...referrer },
          referrer,
        });
      }
    },
  });

  const { data: categories } = useFetchCategoriesQuery(
    { region: userRegion },
    {
      skip: !episode,
      selectFromResult: result => {
        return {
          ...result,
          data: filterEpisodeBannerCategories({
            categories: result.data,
            episode,
            userRegion,
          }),
        };
      },
    },
  );

  const { toggleBookmark, isBookmarking, isBookmarked } = useBookmarkManagement(
    {
      episodeId: episode.episodeId,
      location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.BOOKMARK },
      referrer,
    },
  );

  const [showProductFeedbackIfReady] = useShowProductFeedbackIfReadyMutation();

  const onPlayNow = () => {
    dispatch(
      playerThunks.episodePlayPressed({
        episodeId: episode.episodeId,
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PLAY_NOW },
        referrer,
      }),
    );
  };

  const onTrailer = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: {
          episodeId: episode.episodeId,
          chapterId: episode.trailerId,
        },
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
        referrer,
      }),
    );
  };

  const onShare = () => {
    navigator.clipboard.writeText(episode?.deepLink);
    AnalyticsService.episodeShare({
      episode,
      location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.SHARE },
    });
    SnackbarUtils.success('Link copied to clipboard');
    showProductFeedbackIfReady();
  };

  const onRedeem = () =>
    dispatch(
      userEpisodesThunks.initiateRedemption({ episodeId: episode.episodeId }),
    );

  const isEpisodeEthics = getIsEpisodeEthics({ episode, userRegion });

  return (
    <div ref={ref}>
      <EpisodeBannerComponent
        referrer={referrer}
        episode={episode}
        onPlayNow={onPlayNow}
        onTrailer={onTrailer}
        onShare={onShare}
        onRedeem={onRedeem}
        actions={actions}
        toggleBookmark={toggleBookmark}
        isBookmarking={isBookmarking}
        isBookmarked={isBookmarked}
        isWithEthicsLabel={isEpisodeEthics}
        isWithPremiumLabel={isFreeTrialUser && episode.isPremium}
        isEpisodeRedeemRequired={isEpisodeRedeemRequired}
        categoryTags={categories?.map(c => c.name).join(', ')}
        disableTitleLink={isEpisodeDetails}
        sx={{ mx: isEpisodeDetails ? 0 : APP_LAYOUT_PX }}
      />
    </div>
  );
};
