import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button as MuiButton,
  Hidden,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes, urls } from '@/constants';
import { Link } from '@/containers';
import { LumiQCombinationMarkIcon } from '@/icons';
import {
  appleLogo,
  certificationCan,
  certificationUsa,
  googleLogo,
  mobileDevices,
} from '@/images';
import { useLoginMutation } from '@/store';

import { NASBADisclaimerModal } from './components';
import { FormConfig, getOauthURL } from './utils';

export const Login = () => {
  const inputRef = useRef();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });
  const [showNASBAModal, setShowNASBAModal] = useState(false);

  const [login, { isLoading }] = useLoginMutation();

  const onChange = ({ target: { id, value } }) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onSubmit = async () => {
    let errorFound = false;
    const newErrors = {};
    Object.keys(FormConfig).forEach(id => {
      if (!FormConfig[id].validator(form[id].trim())) {
        newErrors[id] = FormConfig[id].errorMessage;
        errorFound = true;
      }
    });
    setErrors(newErrors);

    if (errorFound) return;

    const { error: requestError } = await login({
      email: form.email.trim(),
      password: form.password.trim(),
    });

    if (requestError) {
      setErrors({
        password:
          requestError?.data?.message === 'Invalid email or password'
            ? 'Invalid email or password'
            : 'Something went wrong. Please try again later.',
      });
    }
  };

  const googleOauthURL = getOauthURL('google');
  const appleOauthURL = getOauthURL('apple');

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={[styles.pageSection, styles.gradientBackground]}>
        <LumiQCombinationMarkIcon
          sx={{ width: 150, height: 39, color: 'white' }}
        />
        <Typography variant="h1" sx={{ color: 'white', mt: 4 }}>
          WELCOME!
        </Typography>
        <Typography variant="h3" sx={{ color: 'white', mt: 2 }}>
          LumiQ is a podcast app where engaging conversations with business
          leaders count as verifiable learning for CPAs.
        </Typography>
        <Hidden mdUp>
          <Button
            variant="warning"
            label="Login"
            sx={{ mt: 2 }}
            skinny={false}
            onClick={() =>
              inputRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          />
          <Button
            variant="warning"
            label="Create Account"
            sx={{ mt: 2 }}
            skinny={false}
            onClick={() => navigate(Routes.REGISTER)}
          />
        </Hidden>
        <Box
          component="img"
          src={mobileDevices}
          alt="mobile devices"
          sx={{ width: '100%', maxWidth: 500, display: 'block', mx: 'auto' }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto' }}>
          <Box
            component="img"
            src={certificationCan}
            alt="canadian certification"
            sx={{ height: 100, p: 2 }}
          />
          <Box
            component="img"
            src={certificationUsa}
            alt="usa certification"
            onClick={() => setShowNASBAModal(true)}
            sx={styles.nasbaDisclaimerIcon}
          />
        </Box>
      </Box>
      <Box sx={styles.pageSection} ref={inputRef}>
        <Typography variant="h1">Login</Typography>
        <Box sx={styles.textFieldContainer}>
          <Typography variant="body3">Email</Typography>
          <TextField
            id="email"
            onChange={onChange}
            error={!!errors.email}
            fullWidth
          />
          {errors.email && (
            <Typography color="red" variant="body3">
              {errors.email}
            </Typography>
          )}
        </Box>
        <Box sx={styles.textFieldContainer}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body3">Password</Typography>
            <Box sx={{ ml: 'auto' }}>
              <Link to={Routes.FORGOT_PASSWORD} inline>
                <Typography color="gray" variant="body3">
                  Forgot Password?
                </Typography>
              </Link>
            </Box>
          </Box>
          <TextField
            type="password"
            id="password"
            onChange={onChange}
            error={!!errors.password}
            fullWidth
          />
          {errors.password && (
            <Typography color="red" variant="body3">
              {errors.password}
            </Typography>
          )}
        </Box>
        <Box sx={styles.submitContainer}>
          <Button
            disabled={isLoading}
            onClick={onSubmit}
            variant="primaryGradient"
            label="Login"
            skinny={false}
            sx={{ flex: 1 }}
          />
          <Box sx={styles.registerLinkContainer}>
            <Typography sx={{ textWrap: 'nowrap' }}>New to LumiQ?</Typography>
            <Link to={Routes.REGISTER} inline>
              <Typography variant="body3" sx={{ textWrap: 'nowrap' }}>
                Create an Account! &gt;
              </Typography>
            </Link>
          </Box>
        </Box>
        <Typography variant="h1" sx={{ mt: 4 }}>
          Or Log in With
        </Typography>
        <MuiButton href={googleOauthURL} sx={styles.oauthButton}>
          <Box
            component="img"
            alt="google logo"
            src={googleLogo}
            sx={styles.oauthIcon}
          />
          <Typography variant="button1">Google</Typography>
          <ChevronRight sx={{ ml: 'auto' }} />
        </MuiButton>
        <MuiButton href={appleOauthURL} sx={styles.oauthButton}>
          <Box
            component="img"
            alt="apple logo"
            src={appleLogo}
            sx={styles.oauthIcon}
          />
          <Typography variant="button1">Apple</Typography>
          <ChevronRight sx={{ ml: 'auto' }} />
        </MuiButton>
        <Box sx={{ mt: 'auto', pt: 4 }}>
          <Typography component="span">
            By clicking one of the options above, you agree to our{' '}
          </Typography>
          <Link inline to={urls.lumiqTC}>
            Terms
          </Link>{' '}
          <Typography component="span">and that you have read our </Typography>
          <Link inline to={urls.lumiqPrivacy}>
            Privacy Policy
          </Link>
          <Typography component="span">.</Typography>
        </Box>
      </Box>
      <NASBADisclaimerModal
        isOpen={showNASBAModal}
        onClose={() => setShowNASBAModal(false)}
      />
    </Box>
  );
};

const styles = {
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  pageSection: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: 4,
      sm: 8,
    },
    flex: 1,
  },
  gradientBackground: {
    background: theme => theme.gradients.primary,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: 2,
  },
  submitContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    mt: 3,
  },
  registerLinkContainer: {
    ml: {
      xs: 0,
      sm: 2,
    },
    mt: {
      xs: 2,
      sm: 0,
    },
    flex: 1,
  },
  oauthButton: {
    mt: 2,
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'blueBlack',
    border: theme => `1px solid ${theme.palette.blueBlack}`,
    p: 2,
  },
  oauthIcon: {
    height: 30,
    mr: 2,
  },
  nasbaDisclaimerIcon: {
    height: 100,
    p: 2,
    borderRadius: 2,
    cursor: 'pointer',
    '&:hover': { backgroundColor: 'blueBlackTransparent' },
  },
};
