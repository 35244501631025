import { Box, Typography } from '@mui/material';

import { Link } from '@/containers';
import { LumiQCombinationMarkIcon } from '@/icons';
import { forgotPasswordBackground } from '@/images';

export const ForgotPasswordPageWrapper = ({ children }) => {
  return (
    <Box
      sx={[
        styles.pageContainer,
        styles.centerColumn,
        styles.gradientBackground,
      ]}>
      <Box sx={[styles.pageSection, styles.centerColumn]}>
        <Box sx={styles.centerColumn}>
          <LumiQCombinationMarkIcon
            sx={{
              width: 150,
              height: 39,
              color: 'white',
              mt: 4,
              mb: 2,
            }}
          />
          <Box sx={styles.pagePasswordForm}>{children}</Box>
        </Box>
        <Box sx={[styles.centerColumn, styles.footer]}>
          <Typography variant="body3">Having issues?</Typography>
          <Link color="white" onClick={() => window.Intercom?.('show')}>
            Contact Us
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  centerColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
  },
  pageSection: {
    minHeight: '100vh',
    justifyContent: 'space-between',
    maxWidth: 342,
    width: '100%',
    pt: 4,
    pb: 4,
  },
  gradientBackground: {
    background: theme =>
      `url(${forgotPasswordBackground}), ${theme.gradients.forgotPassword}`,
  },
  pagePasswordForm: {
    backgroundColor: 'white',
    p: 4,
    borderRadius: 1,
    textAlign: 'center',
    width: '100%',
  },
  footer: {
    color: 'white',
    mt: 4,
  },
};
