import { Routes, urls } from '@/constants';

const isStringNotEmpty = string => string?.trim().length > 0;

export const FormConfig = {
  email: {
    validator: isStringNotEmpty,
    errorMessage: 'Please enter a valid email address.',
  },
  password: {
    validator: isStringNotEmpty,
    errorMessage: 'Please enter a valid password.',
  },
};

// TODO: should we be more selective with what query params we pass along?
export const getOauthURL = service => {
  const params = {
    redirect: `${window.location.origin}${Routes.DISCOVER}`,
    ...Object.fromEntries(new URLSearchParams(window.location.search)),
  };

  return `${urls.auth[service]}?${new URLSearchParams(params).toString()}`;
};
