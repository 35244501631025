import { DesignationOption } from 'src/types/onboarding';

import {
  fetchDesignationOptionsRequest,
  finalizeRegistrationRequest,
  sendAccountLinkingEmailRequest,
} from '@/requests';
import { AnalyticsService, EVENTS } from '@/services';
import { Region } from '@/types';
import { camelCaseKeys } from '@/utils';

import { postgrestApi } from '../postgrest-api';
import { PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError, rtkQueryError } from '../store.utils';
import { userApiNode } from '../user-api';

export const onboardingApiPostgrest = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchDesignationOptions: builder.query<DesignationOption[], undefined>({
      query: fetchDesignationOptionsRequest,
      transformResponse: (response: unknown[]) => response.map(camelCaseKeys),
    }),
    sendAccountLinkingEmail: builder.mutation<VoidFunction, { email: string }>({
      query: sendAccountLinkingEmailRequest,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.RESEND_B2B.INTENT);
          await queryFulfilled;
          AnalyticsService.logEvent(
            EVENTS.ONBOARDING_EVENTS.RESEND_B2B.SUCCESS,
          );
        } catch (error: any) {
          AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.RESEND_B2B.FAIL, {
            error: error?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
    }),
    completeOnboarding: builder.mutation<void, { userRegion: Region }>({
      queryFn: async (
        { userRegion },
        { getState, dispatch },
        extraOptions,
        baseQuery,
      ) => {
        try {
          await dispatch(
            userApiNode.endpoints.updateUserRegion.initiate({
              region: userRegion,
            }),
          ).unwrap();

          await baseQuery(finalizeRegistrationRequest());
          return { data: undefined };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.FINALIZE.INTENT);
          await queryFulfilled;
          AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.FINALIZE.SUCCESS);
          dispatch(
            postgrestApi.util.invalidateTags([
              PostgrestApiTags.PLAN,
              PostgrestApiTags.USER_TAGS,
              PostgrestApiTags.PLAYER_CONFIG,
            ]),
          );
        } catch (error: any) {
          AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.FINALIZE.FAIL, {
            error: error?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
    }),
  }),
});

export const {
  useFetchDesignationOptionsQuery,
  useSendAccountLinkingEmailMutation,
  useCompleteOnboardingMutation,
} = onboardingApiPostgrest;
