export const POSTGREST_BASE_URL = 'https://ipa.lumiqlearn.com/app';

const PostgrestFSMEndpoints = {
  PLAYER_SESSIONS: 'player_sessions',
  FIND_PLAYER_SESSION: '/rpc/find_player_session_by_episode_id',
  START_PLAYER_SESSION: 'rpc/start_player_session',
  PLAY_PLAYER_SESSION: '/rpc/play_player_session',
  PAUSE_PLAYER_SESSION: '/rpc/pause_player_session',
  RESUME_LAST_PLAYER_SESSION: 'rpc/resume_last_player_session',
  SET_SESSION_POSITION: 'rpc/set_position',
  SEEK_SESSION_POSITION: 'rpc/seek_position',
  ANSWER_QUIZ: '/rpc/answer_quiz',
} as const;

export const PostgrestUnauthenticatedEndpoints = {
  LOGIN: '/rpc/login',
  PLAYER_CONFIG: '/rpc/player_config',
  EPISODE: '/episodes_v2',
  FEATURED_EPISODES: '/featured_episodes_v2',
  TRENDING_EPISODES: '/trending_episodes_v2',
  CATEGORIES: '/categories',
  FIELDS_OF_STUDY: '/fields_of_study',
  LEARNING_PATHS: '/learning_paths_v2',
  REFRESH_ACCESS_TOKEN: '/rpc/refresh_session_token',
  CHAPTER: '/chapters_v2',
  QUIZ: '/quizzes',
  ASSESSMENTS: '/assessment',
  TRAILER_URL: '/trailer_url',
  DESIGNATION_OPTIONS: '/designation_options',
  TECHNICAL_SEARCH_OPTIONS: '/technical_search_options',
  TECHNICAL_SEARCH_RESULT: '/rpc/technical_search',
  CPA_CANADA_EPISODE: '/cpa_canada_episode',
  OFFERS: '/offers',
  TAX_RATES: '/tax_rates',
  UPDATE_PASSWORD: '/rpc/reset_password',
  FORGOT_PASSWORD: '/rpc/forgot_password_v2',
  VALIDATE_PASSWORD_RESET_TOKEN: '/rpc/validate_password_reset_token',
  RESET_PASSWORD_WITH_TOKEN: '/rpc/reset_password_with_token',
} as const;

export const PostgrestAuthenticatedEndpoints = {
  PROFILE: '/profile',
  USER_REGISTRATION_STATE: '/registration_state',
  USER_TAGS: '/rpc/user_tags',
  UPDATE_USER_DESIGNATIONS: '/rpc/update_designations',
  SEND_ACCOUNT_LINKING_EMAIL: '/rpc/send_activation_email',
  FINALIZE_REGISTRATION: '/rpc/finalize_registration',
  CHAPTER_URL: '/content_url',
  PLAN: '/plan',
  UPDATE_PROFILE: '/rpc/update_profile',
  CREDITS: '/credits',
  ADD_PAYMENT_METHOD: '/rpc/add_payment_method2',
  GET_PAYMENT_ACTION_RESULT: '/payment_action_result',
  GET_PAYMENT_METHODS: '/payment_methods',
  CREATE_CHARGE: '/rpc/charge_sku',
  MY_ORDERS: '/my_orders',
  MY_OFFERS: '/my_offers',
  LINK_PLAN: '/rpc/link_member_to_user',
  ORGANIZATION_PLAYLIST: '/organization_playlist_v2',
  ...PostgrestFSMEndpoints,
} as const;

export const PostgrestEndpoints = {
  ...PostgrestAuthenticatedEndpoints,
  ...PostgrestUnauthenticatedEndpoints,
};

export const EPISODE_CARD_REQUIRED_FIELDS = [
  'episode_id',
  'name',
  'hook',
  'is_archived',
  'total_ethics',
  'is_premium',
  'is_recorded_live',
  'cover',
  'company_id',
  'company_name',
  'company_logo',
  'duration',
  'chapters',
  'trailer_id',
  'is_video',
  'deep_link',
  'categories',
  'field_of_study_id',
] as const;

export const PostgrestErrors = {
  Onboarding: {
    MEMBER_ALREADY_LINKED: 'lumiq.activation.member.already_linked',
    MEMBER_NOT_FOUND: 'lumiq.activation.member.not_found',
    MEMBER_NOT_IN_INVITED_STATE: 'lumiq.activation.member.not_in_invited_state',
    USER_ALREADY_LINKED:
      'lumiq.activation.member.already_linked_to_member_in_org',
  },
};
