import { Box, Fade } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { CAROUSEL_EPISODES_LIMIT } from '@/constants';
import { EpisodeCard, EpisodeCardSkeleton } from '@/containers';
import { EVENT_CONSTANTS } from '@/services';

import { Carousel } from '../Carousel';

export const EpisodeCardCarousel = ({
  title,
  uKey,
  episodes, // TODO unless we change how EpisodeCard works, this should just be an array of ids
  isLoading,
  ViewAllLink,
  Tab,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const episodeList = inView ? episodes : [episodes?.[0]];

  return (
    <div ref={ref}>
      <Carousel title={title} uKey={uKey} ViewAllLink={ViewAllLink} Tab={Tab}>
        {isLoading ? (
          Array.from({ length: CAROUSEL_EPISODES_LIMIT }).map((_, idx) => (
            <Box key={idx} sx={styles.episodeCardContainer}>
              <EpisodeCardSkeleton includeText />
            </Box>
          ))
        ) : (
          <>
            {episodeList.map((episode, index) => (
              <Fade
                in={inView}
                timeout={Math.log10(index + 2) * 1000}
                key={episode.episodeId}>
                <Box sx={styles.episodeCardContainer}>
                  <EpisodeCard
                    episodeId={episode.episodeId}
                    adjustWidth={false}
                    referrer={{
                      page: EVENT_CONSTANTS.PAGE.DISCOVER,
                      carousel: uKey,
                    }}
                  />
                </Box>
              </Fade>
            ))}
          </>
        )}
      </Carousel>
    </div>
  );
};

const styles = {
  episodeCardContainer: {
    mr: {
      xs: 2,
      md: 3,
    },
  },
};
