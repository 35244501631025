import { Box, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Variants } from './constants';

export const Link = ({
  to,
  onClick,
  children,
  variant = Variants.CLASSIC,
  inline = false,
  color,
  ...props
}) => {
  const theme = useTheme();

  if (!onClick && !to) return children;

  return (
    <Box
      onClick={onClick}
      sx={[
        {
          ...theme.typography.body3,
          fontWeight: 700,
          cursor: 'pointer',
          color: 'inherit',
          textDecoration: 'inherit',
        },
        variant === Variants.PLAIN && {
          color: 'inherit',
        },
        variant === Variants.CLASSIC && {
          color: 'blue500',
          ':hover': {
            textDecoration: 'underline',
          },
        },
        inline && {
          display: 'inline',
        },
        color && {
          color,
        },
      ]}>
      {to ? (
        <RouterLink
          style={{
            ...theme.typography.body3,
            color: 'inherit',
            textDecoration: 'inherit',
          }}
          to={to}
          {...props}>
          {children}
        </RouterLink>
      ) : (
        children
      )}
    </Box>
  );
};
