import { Button as MuiButton } from '@mui/material';

export const Variants = {
  PRIMARY: 'primary',
  PRIMARY_GRADIENT: 'primaryGradient',
  SECONDARY: 'secondary',
  DARK: 'dark',
  OUTLINED: 'outlined',
  WARNING: 'warning',
};

export const Button = ({
  onClick,
  label,
  variant = 'primary',
  startIcon = null,
  disabled,
  fullWidth = true,
  skinny = true,
  sx,
}) => (
  <MuiButton
    onClick={onClick}
    sx={[
      sx,
      styles.button,
      styles[variant],
      !fullWidth && { minWidth: 'fit-content' },
      !skinny && { height: 48 },
      !label && {
        '& .MuiButton-startIcon': {
          mr: 0,
        },
      },
    ]}
    variant={variant === 'outlined' ? 'outlined' : 'contained'}
    startIcon={startIcon}
    disableRipple
    disableElevation
    disabled={disabled}
    fullWidth={fullWidth}>
    {label}
  </MuiButton>
);

const styles = {
  button: theme => ({
    ...theme.typography.button1,
    height: 40,
    py: 1.5,
    px: 2,
    borderRadius: 6,
    textWrap: 'noWrap',
    textTransform: 'none',
    overflow: 'hidden',
    transition:
      'background-color 0.2s, opacity 0.2s, color 0.2s, border-color 0.2s',
    '& .MuiButton-startIcon': {
      mr: 1,
      ml: 0,
    },
    '&:hover': {
      opacity: 0.8,
    },
  }),
  primary: {
    '&:hover': {
      backgroundColor: 'bluePrimary',
    },
    backgroundColor: 'bluePrimary',
    color: 'white',
  },
  primaryGradient: {
    '&:hover': {
      background: theme => theme.gradients.teal,
    },
    background: theme => theme.gradients.teal,
    color: 'white',
  },
  secondary: {
    '&:hover': {
      backgroundColor: 'grayLight',
    },
    backgroundColor: 'grayLight',
    color: 'black',
  },
  dark: {
    backgroundColor: 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      opacity: 1,
    },
  },
  warning: ({ gradients, customShadows }) => ({
    boxShadow: customShadows.button,
    background: gradients.orange,
    color: 'white',
    '&:hover': {
      background: gradients.orange,
      boxShadow: customShadows.button,
    },
  }),
  outlined: {
    '&:hover': {
      color: 'bluePrimary',
      borderColor: 'bluePrimary',
      opacity: 1,
    },
    color: 'blueBlack',
    borderColor: 'blueBlack',
  },
};
