import { MenuBook } from '@mui/icons-material';
import { Box, Grid, useMediaQuery } from '@mui/material';
import * as R from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button, PageTabs } from '@/components';
import { Modals } from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  selectSessionUserId,
  selectUserRegion,
  uiActions,
  useFetchEpisodeChaptersQuery,
  useFetchEpisodePendingQuizzesQuery,
} from '@/store';
import { getIsBranchLink, getUtmParameters } from '@/utils';

import { EpisodeBanner } from '../EpisodeBanner';
import {
  Assessment,
  ChapterDescription,
  EpisodeChapters,
  EpisodeDescription,
  EpisodeEvaluation,
  Glossary,
  KeyTakeaways,
  SpeakerBio,
  WarningBanners,
} from './components';

export const EpisodeDetails = ({ episode, bannerActions }) => {
  const { episodeId } = episode;
  const region = useSelector(selectUserRegion);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { data: chapters, isLoading: isChaptersLoading } =
    useFetchEpisodeChaptersQuery(
      { episodeId, region },
      {
        selectFromResult: result => ({
          ...result,
          data: result.data?.filter(r => !r.isTrailer),
        }),
        skip: !episodeId,
      },
    );

  const { data: quizzes, refetch: refetchQuizzes } =
    useFetchEpisodePendingQuizzesQuery(
      {
        episodeId,
        region,
      },
      {
        skip: !episodeId,
      },
    );

  const [selectedTab, setSelectedTab] = useState('description');
  const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);

  const detailsRef = useRef();

  const webLocation = useLocation();
  const [referrer, setReferrer] = useState();

  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);

  useEffect(() => {
    const { search, state } = webLocation;

    if (!referrer) {
      setReferrer(
        state?.referrer || {
          source: getIsBranchLink(search) ? 'branch' : 'direct',
          ...getUtmParameters(search),
        },
      );
    }
  }, [webLocation, referrer]);

  useEffect(() => {
    if (R.isNil(selectedChapterIndex) && chapters?.length) {
      const activeQuizChapterId = webLocation.state?.activeQuizChapterId;
      if (activeQuizChapterId) {
        const chapterIndex = chapters.findIndex(
          ({ chapterId }) => chapterId === activeQuizChapterId,
        );
        if (chapterIndex > -1) {
          setSelectedChapterIndex(chapterIndex);
          detailsRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }
      setSelectedChapterIndex(0);
    }
  }, [webLocation, selectedChapterIndex, chapters]);

  const tabs = [
    { label: 'Episode Description', value: 'description' },
    { label: 'Speaker Bio', value: 'speakerBio' },
    {
      label: region === 'CAN' ? 'Key Takeaways' : 'Learning Objectives',
      value: 'takeaways',
    },
  ];

  if (episode.glossary?.length > 0) {
    tabs.push({
      label: 'Glossary',
      value: 'glossary',
    });
  }

  const visualAidUrl = episode.visualAid?.horizontalPdf?.url;

  return (
    <Box>
      <EpisodeBanner
        episode={episode}
        actions={bannerActions}
        referrer={{
          page: EVENT_CONSTANTS.PAGE.EPISODE_DETAILS,
          component: EVENT_CONSTANTS.COMPONENT.EPISODE_BANNER,
        }}
      />
      <Box mt={3}>
        <WarningBanners episode={episode} />
      </Box>
      <Box
        sx={{
          mt: { xs: 0, sm: 2 },
          display: 'flex',
          alignItems: { xs: 'flex-start', sm: 'center' },
          justifyContent: 'space-between',
          flexGrow: 1,
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        <PageTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={(_, value) => {
            setSelectedTab(value);
            AnalyticsService.episodeDetailSwitchTab({ episode, tab: value });
          }}
        />
        {visualAidUrl && (
          <Box
            sx={theme => ({
              ml: 3,
              mt: 0,
              width: 'auto',
              [theme.breakpoints.down('sm')]: { ml: 0, mt: 2, width: '100%' },
            })}>
            <Button
              variant="primary"
              label="Visual Aid"
              fullWidth={isMobile}
              startIcon={<MenuBook />}
              disabled={episode.isArchived}
              onClick={() => {
                if (!userId) {
                  dispatch(uiActions.setActiveModal({ name: Modals.SIGN_UP }));
                  return;
                }

                window.open(visualAidUrl);
                AnalyticsService.visualAidView({
                  episode,
                });
              }}
            />
          </Box>
        )}
      </Box>

      <Box mt={2}>
        {selectedTab === 'description' && (
          <EpisodeDescription
            episode={episode}
            referrer={{ page: EVENT_CONSTANTS.PAGE.EPISODE_DETAILS }}
          />
        )}
        {selectedTab === 'speakerBio' && <SpeakerBio episode={episode} />}
        {selectedTab === 'takeaways' && <KeyTakeaways episode={episode} />}
        {selectedTab === 'glossary' && <Glossary episode={episode} />}
        {isChaptersLoading || !chapters?.length ? null : (
          <Grid container>
            <Grid item xs={12} md={6} sx={{ pr: { md: 3 } }}>
              <EpisodeChapters
                episode={episode}
                chapters={chapters}
                selectedChapterIndex={selectedChapterIndex}
                onSelectChapter={chapterIndex =>
                  setSelectedChapterIndex(chapterIndex)
                }
                referrer={{ page: EVENT_CONSTANTS.PAGE.EPISODE_DETAILS }}
              />
              <Assessment episodeId={episodeId} />
              <EpisodeEvaluation episodeId={episodeId} />
            </Grid>
            <Grid item xs={12} md={6} pl={{ sm: 3 }} ref={detailsRef}>
              <ChapterDescription
                chapter={chapters?.[selectedChapterIndex]}
                chapterIndex={selectedChapterIndex}
                maxChapterIndex={chapters?.length - 1}
                quizzes={quizzes?.filter(
                  quiz =>
                    quiz.chapterId ===
                    chapters?.[selectedChapterIndex]?.chapterId,
                )}
                onQuizSuccess={refetchQuizzes}
                onSelectChapter={chapterIndex =>
                  setSelectedChapterIndex(chapterIndex)
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
