import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectSessionUserId,
  selectUserRegion,
  useFetchPlanQuery,
  useFetchUserProfileQuery,
  useFetchUserTagsQuery,
} from '@/store';
import { Plan, Region, UserProfile } from '@/types';

export interface InitProps {
  userId?: string;
  userProfile?: UserProfile;
  plan?: Plan;
  userRegion?: Region;
  userTags?: string[];
}

export const useInitHook = (callback: (props: InitProps) => void) => {
  const [initialized, setInitialized] = useState(false);
  const userRegion = useSelector(selectUserRegion);

  const userId = useSelector(selectSessionUserId);
  const userQueryArg = userId ? undefined : skipToken;
  const { data: userProfile } = useFetchUserProfileQuery(userQueryArg);
  const { data: plan } = useFetchPlanQuery(userQueryArg);
  const { data: userTags } = useFetchUserTagsQuery(userQueryArg);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      callback({
        userId,
        userProfile,
        userRegion,
        plan,
        userTags,
      });
    }
  }, [userRegion, userProfile, initialized, userId, userTags, callback, plan]);
};
