import { ConfirmationNumberRounded } from '@mui/icons-material';
import { alpha, Box, Hidden, Typography } from '@mui/material';

import { Button } from '@/components';
import { Routes } from '@/constants';
import {
  BookmarkIcon,
  EthicsLabelIcon,
  PlayArrowFilledIcon,
  PlayArrowOutlinedIcon,
  PremiumLabelIcon,
  ShareIcon,
} from '@/icons';
import { createRouteWithParams } from '@/utils';

import { Link } from '../Link';

export const EpisodeBanner = ({
  episode,
  onTrailer,
  onPlayNow,
  onShare,
  onRedeem,
  actions,
  toggleBookmark,
  isBookmarking,
  isBookmarked,
  isWithEthicsLabel,
  isWithPremiumLabel,
  isEpisodeRedeemRequired,
  categoryTags,
  disableTitleLink,
  referrer,
  sx,
}) => {
  return (
    <Box
      sx={[
        sx,
        {
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 12,
          height: HEIGHT,
          color: 'text.white',
        },
      ]}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          background: `url(${episode.cover}) 50% / cover no-repeat`,
          filter: 'blur(23.5px)',
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bgcolor: theme => alpha(theme.palette.blueBlack, 0.69),
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(23.5px)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          p: 3,
          willChange: 'transform',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pr: { xs: 2, md: 3 },
          }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
            }}>
            <Link
              to={
                disableTitleLink
                  ? null
                  : createRouteWithParams(Routes.EPISODE, {
                      episodeId: episode.episodeId,
                    })
              }
              state={{ referrer }}
              color="inherit">
              <Typography variant="h2" mb={1}>
                {episode.name}
              </Typography>
            </Link>
            <Typography variant="subtitle1" gutterBottom>
              {episode.hook}
            </Typography>
            <Hidden smDown>
              <Typography variant="subtitle3" fontStyle={'italic'}>
                {categoryTags}
              </Typography>
            </Hidden>
          </Box>
          {actions || (
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isEpisodeRedeemRequired ? (
                <Button
                  label={'Redeem'}
                  variant="warning"
                  onClick={onRedeem}
                  skinny={false}
                  fullWidth={false}
                  startIcon={<ConfirmationNumberRounded />}
                />
              ) : (
                <Button
                  variant="warning"
                  onClick={onPlayNow}
                  disabled={episode.isArchived}
                  label={'Play Now'}
                  skinny={false}
                  fullWidth={false}
                  startIcon={<PlayArrowFilledIcon />}
                />
              )}
              <Hidden mdDown>
                <Button
                  variant="dark"
                  label="Preview"
                  fullWidth={false}
                  skinny={false}
                  startIcon={<PlayArrowOutlinedIcon />}
                  onClick={onTrailer}
                  disabled={episode.isArchived}
                />
              </Hidden>

              <Button
                variant="dark"
                onClick={toggleBookmark}
                disabled={episode.isArchived || isBookmarking}
                label={'Bookmark'}
                skinny={false}
                fullWidth={false}
                startIcon={
                  <BookmarkIcon
                    sx={{
                      color: isBookmarked ? 'aqua' : 'white',
                    }}
                  />
                }
              />
              <Hidden mdDown>
                <Button
                  variant="dark"
                  onClick={onShare}
                  disabled={episode.isArchived}
                  label={'Share'}
                  skinny={false}
                  fullWidth={false}
                  startIcon={<ShareIcon />}
                />
              </Hidden>
            </Box>
          )}
        </Box>
        <Hidden smDown>
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              src={episode.cover}
              sx={{
                borderRadius: 6,
                objectFit: 'cover',
                width: { xs: 212, lg: 342 },
                height: 212,
              }}
            />
            {!!episode.companyLogo && (
              <Box
                component="img"
                src={episode.companyLogo}
                alt="company logo"
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  borderRadius: 2,
                  height: 60,
                  width: 60,
                }}
              />
            )}
          </Box>
        </Hidden>
        {isWithEthicsLabel && (
          <EthicsLabelIcon
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 90,
              height: 90,
            }}
          />
        )}
        {isWithPremiumLabel && (
          <PremiumLabelIcon
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 118,
              height: 118,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export const HEIGHT = 260;
