import { PostgrestEndpoints } from '@/requests';

import { postgrestApi } from '../postgrest-api';
import { setTokens } from '../session';
import { handleOnQueryStartedError } from '../store.utils';

type LoginPayload = {
  email: string;
  password: string;
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

type SendResetPasswordEmailPayload = {
  email: string;
};

type ValidatePasswordResetTokenPayload = {
  token: string;
};

type ResetPasswordWithTokenPayload = {
  password: string;
  token: string;
};

export const authApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: ({ email, password }) => ({
        url: PostgrestEndpoints.LOGIN,
        method: 'POST',
        body: {
          email,
          password,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { accessToken, refreshToken },
          } = await queryFulfilled;

          await dispatch(setTokens({ accessToken, refreshToken }));
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (res: any) => {
        return {
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          userId: res.user.user_id,
        };
      },
    }),
    sendResetPasswordEmail: builder.mutation<
      any,
      SendResetPasswordEmailPayload
    >({
      query: ({ email }) => ({
        url: PostgrestEndpoints.FORGOT_PASSWORD,
        method: 'POST',
        body: { email },
      }),
    }),
    validatePasswordResetToken: builder.query<
      boolean,
      ValidatePasswordResetTokenPayload
    >({
      query: ({ token }) => ({
        url: PostgrestEndpoints.VALIDATE_PASSWORD_RESET_TOKEN,
        method: 'POST',
        body: { _password_reset_token: token },
      }),
    }),
    resetPasswordWithToken: builder.mutation<
      any,
      ResetPasswordWithTokenPayload
    >({
      query: ({ password, token }) => ({
        url: PostgrestEndpoints.RESET_PASSWORD_WITH_TOKEN,
        method: 'POST',
        body: {
          _password_reset_token: token,
          _password: password,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSendResetPasswordEmailMutation,
  useValidatePasswordResetTokenQuery,
  useResetPasswordWithTokenMutation,
} = authApi;
