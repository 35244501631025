import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';

import { Modals } from '@/constants';
import { AnalyticsService } from '@/services';
import {
  selectEpisode,
  selectSessionUserId,
  selectUserRegion,
  uiActions,
  useBookmarkEpisodeMutation,
  useFetchEpisodeQuery,
  useFetchUserEpisodesQuery,
} from '@/store';
import { RootState } from '@/store/store';
import { SnackbarUtils } from '@/utils';

export const useBookmarkManagement = ({
  episodeId,
  referrer,
  location,
}: {
  episodeId: string;
  referrer: Record<string, unknown>; // narrow types when typing analytics
  location: Record<string, unknown>; // narrow types when typing analytics
}) => {
  const dispatch = useDispatch();
  const region = useSelector(selectUserRegion);
  const [bookmarkEpisode, { isLoading }] = useBookmarkEpisodeMutation();
  const userId = useSelector(selectSessionUserId);

  const { data } = useFetchUserEpisodesQuery(userId ? undefined : skipToken);

  const isBookmarked = data?.entities[episodeId]?.isBookmarked;

  const storeEpisode = useSelector((state: RootState) =>
    selectEpisode(state, episodeId),
  );

  const { data: queryEpisode } = useFetchEpisodeQuery(
    {
      episodeId,
      region,
    },
    { skip: !episodeId || !!storeEpisode },
  );

  const episode = storeEpisode || queryEpisode;

  return {
    toggleBookmark: async (overrides = {}) => {
      if (!userId) {
        dispatch(uiActions.setActiveModal({ name: Modals.SIGN_UP }));
        return;
      }

      const params = {
        episodeId,
        bookmarked: !isBookmarked,
        ...overrides,
      };
      const bookmarkEpisodeResponse = await bookmarkEpisode(params);

      AnalyticsService.bookmark({
        episode,
        value: params.bookmarked,
        location,
        referrer,
      });

      if ('error' in bookmarkEpisodeResponse) {
        SnackbarUtils.error('There was a problem updating the bookmark');
      } else {
        SnackbarUtils.success(
          params.bookmarked ? 'Episode bookmarked' : 'Episode bookmark removed',
        );
      }
    },
    isBookmarking: isLoading,
    isBookmarked,
  };
};
